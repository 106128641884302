import _tasks from "./managers/tasks";
import _async from "./providers/async";
import _stream from "./providers/stream";
import _sync from "./providers/sync";
import _settings from "./settings";
import _utils from "./utils";
var exports = {};
const taskManager = _tasks;
const async_1 = _async;
const stream_1 = _stream;
const sync_1 = _sync;
const settings_1 = _settings;
const utils = _utils;
async function FastGlob(source, options) {
  assertPatternsInput(source);
  const works = getWorks(source, async_1.default, options);
  const result = await Promise.all(works);
  return utils.array.flatten(result);
}
// https://github.com/typescript-eslint/typescript-eslint/issues/60
// eslint-disable-next-line no-redeclare
(function (FastGlob) {
  FastGlob.glob = FastGlob;
  FastGlob.globSync = sync;
  FastGlob.globStream = stream;
  FastGlob.async = FastGlob;
  function sync(source, options) {
    assertPatternsInput(source);
    const works = getWorks(source, sync_1.default, options);
    return utils.array.flatten(works);
  }
  FastGlob.sync = sync;
  function stream(source, options) {
    assertPatternsInput(source);
    const works = getWorks(source, stream_1.default, options);
    /**
     * The stream returned by the provider cannot work with an asynchronous iterator.
     * To support asynchronous iterators, regardless of the number of tasks, we always multiplex streams.
     * This affects performance (+25%). I don't see best solution right now.
     */
    return utils.stream.merge(works);
  }
  FastGlob.stream = stream;
  function generateTasks(source, options) {
    assertPatternsInput(source);
    const patterns = [].concat(source);
    const settings = new settings_1.default(options);
    return taskManager.generate(patterns, settings);
  }
  FastGlob.generateTasks = generateTasks;
  function isDynamicPattern(source, options) {
    assertPatternsInput(source);
    const settings = new settings_1.default(options);
    return utils.pattern.isDynamicPattern(source, settings);
  }
  FastGlob.isDynamicPattern = isDynamicPattern;
  function escapePath(source) {
    assertPatternsInput(source);
    return utils.path.escape(source);
  }
  FastGlob.escapePath = escapePath;
  function convertPathToPattern(source) {
    assertPatternsInput(source);
    return utils.path.convertPathToPattern(source);
  }
  FastGlob.convertPathToPattern = convertPathToPattern;
  let posix;
  (function (posix) {
    function escapePath(source) {
      assertPatternsInput(source);
      return utils.path.escapePosixPath(source);
    }
    posix.escapePath = escapePath;
    function convertPathToPattern(source) {
      assertPatternsInput(source);
      return utils.path.convertPosixPathToPattern(source);
    }
    posix.convertPathToPattern = convertPathToPattern;
  })(posix = FastGlob.posix || (FastGlob.posix = {}));
  let win32;
  (function (win32) {
    function escapePath(source) {
      assertPatternsInput(source);
      return utils.path.escapeWindowsPath(source);
    }
    win32.escapePath = escapePath;
    function convertPathToPattern(source) {
      assertPatternsInput(source);
      return utils.path.convertWindowsPathToPattern(source);
    }
    win32.convertPathToPattern = convertPathToPattern;
  })(win32 = FastGlob.win32 || (FastGlob.win32 = {}));
})(FastGlob || (FastGlob = {}));
function getWorks(source, _Provider, options) {
  const patterns = [].concat(source);
  const settings = new settings_1.default(options);
  const tasks = taskManager.generate(patterns, settings);
  const provider = new _Provider(settings);
  return tasks.map(provider.read, provider);
}
function assertPatternsInput(input) {
  const source = [].concat(input);
  const isValidSource = source.every(item => utils.string.isString(item) && !utils.string.isEmpty(item));
  if (!isValidSource) {
    throw new TypeError("Patterns must be a string (non empty) or an array of strings");
  }
}
exports = FastGlob;
export default exports;