var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.splitWhen = exports.flatten = void 0;
function flatten(items) {
  return items.reduce((collection, item) => [].concat(collection, item), []);
}
exports.flatten = flatten;
function splitWhen(items, predicate) {
  const result = [[]];
  let groupIndex = 0;
  for (const item of items) {
    if (predicate(item)) {
      groupIndex++;
      result[groupIndex] = [];
    } else {
      result[groupIndex].push(item);
    }
  }
  return result;
}
exports.splitWhen = splitWhen;
export default exports;