import _sync from "../readers/sync";
import _provider from "./provider";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const sync_1 = _sync;
const provider_1 = _provider;
class ProviderSync extends provider_1.default {
  constructor() {
    super(...arguments);
    this._reader = new sync_1.default(this._settings);
  }
  read(task) {
    const root = this._getRootDirectory(task);
    const options = this._getReaderOptions(task);
    const entries = this.api(root, task, options);
    return entries.map(options.transform);
  }
  api(root, task, options) {
    if (task.dynamic) {
      return this._reader.dynamic(root, options);
    }
    return this._reader.static(task.patterns, options);
  }
}
exports.default = ProviderSync;
export default exports;