var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isEmpty = exports.isString = void 0;
function isString(input) {
  return typeof input === "string";
}
exports.isString = isString;
function isEmpty(input) {
  return input === "";
}
exports.isEmpty = isEmpty;
export default exports;