import _utils from "../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertPatternGroupToTask = exports.convertPatternGroupsToTasks = exports.groupPatternsByBaseDirectory = exports.getNegativePatternsAsPositive = exports.getPositivePatterns = exports.convertPatternsToTasks = exports.generate = void 0;
const utils = _utils;
function generate(input, settings) {
  const patterns = processPatterns(input, settings);
  const ignore = processPatterns(settings.ignore, settings);
  const positivePatterns = getPositivePatterns(patterns);
  const negativePatterns = getNegativePatternsAsPositive(patterns, ignore);
  const staticPatterns = positivePatterns.filter(pattern => utils.pattern.isStaticPattern(pattern, settings));
  const dynamicPatterns = positivePatterns.filter(pattern => utils.pattern.isDynamicPattern(pattern, settings));
  const staticTasks = convertPatternsToTasks(staticPatterns, negativePatterns, /* dynamic */false);
  const dynamicTasks = convertPatternsToTasks(dynamicPatterns, negativePatterns, /* dynamic */true);
  return staticTasks.concat(dynamicTasks);
}
exports.generate = generate;
function processPatterns(input, settings) {
  let patterns = input;
  /**
   * The original pattern like `{,*,**,a/*}` can lead to problems checking the depth when matching entry
   * and some problems with the micromatch package (see fast-glob issues: #365, #394).
   *
   * To solve this problem, we expand all patterns containing brace expansion. This can lead to a slight slowdown
   * in matching in the case of a large set of patterns after expansion.
   */
  if (settings.braceExpansion) {
    patterns = utils.pattern.expandPatternsWithBraceExpansion(patterns);
  }
  /**
   * If the `baseNameMatch` option is enabled, we must add globstar to patterns, so that they can be used
   * at any nesting level.
   *
   * We do this here, because otherwise we have to complicate the filtering logic. For example, we need to change
   * the pattern in the filter before creating a regular expression. There is no need to change the patterns
   * in the application. Only on the input.
   */
  if (settings.baseNameMatch) {
    patterns = patterns.map(pattern => pattern.includes("/") ? pattern : `**/${pattern}`);
  }
  /**
   * This method also removes duplicate slashes that may have been in the pattern or formed as a result of expansion.
   */
  return patterns.map(pattern => utils.pattern.removeDuplicateSlashes(pattern));
}
/**
 * Returns tasks grouped by basic pattern directories.
 *
 * Patterns that can be found inside (`./`) and outside (`../`) the current directory are handled separately.
 * This is necessary because directory traversal starts at the base directory and goes deeper.
 */
function convertPatternsToTasks(positive, negative, dynamic) {
  const tasks = [];
  const patternsOutsideCurrentDirectory = utils.pattern.getPatternsOutsideCurrentDirectory(positive);
  const patternsInsideCurrentDirectory = utils.pattern.getPatternsInsideCurrentDirectory(positive);
  const outsideCurrentDirectoryGroup = groupPatternsByBaseDirectory(patternsOutsideCurrentDirectory);
  const insideCurrentDirectoryGroup = groupPatternsByBaseDirectory(patternsInsideCurrentDirectory);
  tasks.push(...convertPatternGroupsToTasks(outsideCurrentDirectoryGroup, negative, dynamic));
  /*
   * For the sake of reducing future accesses to the file system, we merge all tasks within the current directory
   * into a global task, if at least one pattern refers to the root (`.`). In this case, the global task covers the rest.
   */
  if ("." in insideCurrentDirectoryGroup) {
    tasks.push(convertPatternGroupToTask(".", patternsInsideCurrentDirectory, negative, dynamic));
  } else {
    tasks.push(...convertPatternGroupsToTasks(insideCurrentDirectoryGroup, negative, dynamic));
  }
  return tasks;
}
exports.convertPatternsToTasks = convertPatternsToTasks;
function getPositivePatterns(patterns) {
  return utils.pattern.getPositivePatterns(patterns);
}
exports.getPositivePatterns = getPositivePatterns;
function getNegativePatternsAsPositive(patterns, ignore) {
  const negative = utils.pattern.getNegativePatterns(patterns).concat(ignore);
  const positive = negative.map(utils.pattern.convertToPositivePattern);
  return positive;
}
exports.getNegativePatternsAsPositive = getNegativePatternsAsPositive;
function groupPatternsByBaseDirectory(patterns) {
  const group = {};
  return patterns.reduce((collection, pattern) => {
    const base = utils.pattern.getBaseDirectory(pattern);
    if (base in collection) {
      collection[base].push(pattern);
    } else {
      collection[base] = [pattern];
    }
    return collection;
  }, group);
}
exports.groupPatternsByBaseDirectory = groupPatternsByBaseDirectory;
function convertPatternGroupsToTasks(positive, negative, dynamic) {
  return Object.keys(positive).map(base => {
    return convertPatternGroupToTask(base, positive[base], negative, dynamic);
  });
}
exports.convertPatternGroupsToTasks = convertPatternGroupsToTasks;
function convertPatternGroupToTask(base, positive, negative, dynamic) {
  return {
    dynamic,
    positive,
    negative,
    base,
    patterns: [].concat(positive, negative.map(utils.pattern.convertToNegativePattern))
  };
}
exports.convertPatternGroupToTask = convertPatternGroupToTask;
export default exports;