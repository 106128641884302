import _utils from "../../utils";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const utils = _utils;
class EntryFilter {
  constructor(_settings, _micromatchOptions) {
    this._settings = _settings;
    this._micromatchOptions = _micromatchOptions;
    this.index = new Map();
  }
  getFilter(positive, negative) {
    const positiveRe = utils.pattern.convertPatternsToRe(positive, this._micromatchOptions);
    const negativeRe = utils.pattern.convertPatternsToRe(negative, Object.assign(Object.assign({}, this._micromatchOptions), {
      dot: true
    }));
    return entry => this._filter(entry, positiveRe, negativeRe);
  }
  _filter(entry, positiveRe, negativeRe) {
    const filepath = utils.path.removeLeadingDotSegment(entry.path);
    if (this._settings.unique && this._isDuplicateEntry(filepath)) {
      return false;
    }
    if (this._onlyFileFilter(entry) || this._onlyDirectoryFilter(entry)) {
      return false;
    }
    if (this._isSkippedByAbsoluteNegativePatterns(filepath, negativeRe)) {
      return false;
    }
    const isDirectory = entry.dirent.isDirectory();
    const isMatched = this._isMatchToPatterns(filepath, positiveRe, isDirectory) && !this._isMatchToPatterns(filepath, negativeRe, isDirectory);
    if (this._settings.unique && isMatched) {
      this._createIndexRecord(filepath);
    }
    return isMatched;
  }
  _isDuplicateEntry(filepath) {
    return this.index.has(filepath);
  }
  _createIndexRecord(filepath) {
    this.index.set(filepath, undefined);
  }
  _onlyFileFilter(entry) {
    return this._settings.onlyFiles && !entry.dirent.isFile();
  }
  _onlyDirectoryFilter(entry) {
    return this._settings.onlyDirectories && !entry.dirent.isDirectory();
  }
  _isSkippedByAbsoluteNegativePatterns(entryPath, patternsRe) {
    if (!this._settings.absolute) {
      return false;
    }
    const fullpath = utils.path.makeAbsolute(this._settings.cwd, entryPath);
    return utils.pattern.matchAny(fullpath, patternsRe);
  }
  _isMatchToPatterns(filepath, patternsRe, isDirectory) {
    // Trying to match files and directories by patterns.
    const isMatched = utils.pattern.matchAny(filepath, patternsRe);
    // A pattern with a trailling slash can be used for directory matching.
    // To apply such pattern, we need to add a tralling slash to the path.
    if (!isMatched && isDirectory) {
      return utils.pattern.matchAny(filepath + "/", patternsRe);
    }
    return isMatched;
  }
}
exports.default = EntryFilter;
export default exports;