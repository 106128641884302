import * as _stream2 from "stream";
var _stream = _stream2;
try {
  if ("default" in _stream2) _stream = _stream2.default;
} catch (e) {}
import * as _fs2 from "@nodelib/fs.stat";
var _fs = _fs2;
try {
  if ("default" in _fs2) _fs = _fs2.default;
} catch (e) {}
import * as _fs4 from "@nodelib/fs.walk";
var _fs3 = _fs4;
try {
  if ("default" in _fs4) _fs3 = _fs4.default;
} catch (e) {}
import _reader from "./reader";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const stream_1 = _stream;
const fsStat = _fs;
const fsWalk = _fs3;
const reader_1 = _reader;
class ReaderStream extends reader_1.default {
  constructor() {
    super(...arguments);
    this._walkStream = fsWalk.walkStream;
    this._stat = fsStat.stat;
  }
  dynamic(root, options) {
    return this._walkStream(root, options);
  }
  static(patterns, options) {
    const filepaths = patterns.map(this._getFullEntryPath, this);
    const stream = new stream_1.PassThrough({
      objectMode: true
    });
    stream._write = (index, _enc, done) => {
      return this._getEntry(filepaths[index], patterns[index], options).then(entry => {
        if (entry !== null && options.entryFilter(entry)) {
          stream.push(entry);
        }
        if (index === filepaths.length - 1) {
          stream.end();
        }
        done();
      }).catch(done);
    };
    for (let i = 0; i < filepaths.length; i++) {
      stream.write(i);
    }
    return stream;
  }
  _getEntry(filepath, pattern, options) {
    return this._getStat(filepath).then(stats => this._makeEntry(stats, pattern)).catch(error => {
      if (options.errorFilter(error)) {
        return null;
      }
      throw error;
    });
  }
  _getStat(filepath) {
    return new Promise((resolve, reject) => {
      this._stat(filepath, this._fsStatSettings, (error, stats) => {
        return error === null ? resolve(stats) : reject(error);
      });
    });
  }
}
exports.default = ReaderStream;
export default exports;