import * as _merge2 from "merge2";
var _merge = _merge2;
try {
  if ("default" in _merge2) _merge = _merge2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merge = void 0;
const merge2 = _merge;
function merge(streams) {
  const mergedStream = merge2(streams);
  streams.forEach(stream => {
    stream.once("error", error => mergedStream.emit("error", error));
  });
  mergedStream.once("close", () => propagateCloseEventToSources(streams));
  mergedStream.once("end", () => propagateCloseEventToSources(streams));
  return mergedStream;
}
exports.merge = merge;
function propagateCloseEventToSources(streams) {
  streams.forEach(stream => stream.emit("close"));
}
export default exports;